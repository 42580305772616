import { isNumber, isString } from "lodash";
import { CSSProperties, ReactNode, useMemo } from "react";

import { Color } from "@api";

import { cx } from "@utils/class-names";
import { fromColor, toColorVar, toInverseColorVar } from "@utils/color";
import { Fn } from "@utils/fn";
import { ifDo } from "@utils/logic";
import { Maybe, when } from "@utils/maybe";
import { ComponentOrNode } from "@utils/react";

import styles from "./square.module.css";

export type Props = {
  color?: Maybe<Color>;
  size?: "fill" | "small" | "medium" | "large" | number;
  className?: string;
  border?: boolean;
  indicator?: ReactNode;
  style?: CSSProperties;
  children?: ReactNode;
  onClick?: Fn<React.MouseEvent, void>;
};

export const SquareIcon = ({
  children,
  color,
  size,
  border,
  indicator,
  className,
  onClick,
  style: _style,
}: Props) => {
  const style = useMemo(
    () => ({
      ..._style,
      ...when(isNumber(size) ? size : undefined, (size) => ({
        width: size,
        height: size,
      })),
      ...ifDo(size === "fill", () => ({
        width: "100%",
        height: "100%",
      })),
      ...when(color ? fromColor(color) : undefined, ([color, shade]) => ({
        ..._style,
        backgroundColor: toColorVar(color, shade),
        color: toInverseColorVar(color, shade),
        border: border
          ? `1px solid ${toColorVar(color, Math.max(shade - 2, 0))}`
          : undefined,
      })),
    }),
    [_style, color, size, border]
  );

  return (
    <span
      className={cx(styles.square, isString(size) && styles[size], className)}
      style={style}
      onClick={onClick}
    >
      {children}
      {indicator && <div className={styles.indicator}>{indicator}</div>}
    </span>
  );
};
