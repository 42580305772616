import { isPerson, isTeam, Person, Team, Workspace } from "@api";

import { cx } from "@utils/class-names";
import { Maybe } from "@utils/maybe";

import { Icon } from ".";
import { Props as SquareIconProps, SquareIcon } from "./square";
import { CompanyFilled, EyeSlash } from "./svgs";

import styles from "./styles.module.css";

type TeamProps = Partial<SquareIconProps> & {
  team: Maybe<Team>;
};

export const TeamIcon = ({
  team,
  color,
  className,
  style,
  size,
  ...rest
}: TeamProps) => {
  return (
    <SquareIcon
      {...rest}
      color={color || team?.color || "gray_5"}
      className={cx(size === "fill" && styles.fill, className)}
      style={style}
      children={team?.name.substring(0, 1) || <EyeSlash />}
    />
  );
};

export const SpaceIcon = ({
  entity,
}: {
  entity: Maybe<Team | Person | Workspace>;
}) =>
  isTeam(entity) ? (
    <TeamIcon team={entity} />
  ) : isPerson(entity) ? (
    <SquareIcon color="gray_5">
      <Icon icon={EyeSlash} />
    </SquareIcon>
  ) : (
    <SquareIcon color="gray_5">
      <Icon icon={CompanyFilled} />
    </SquareIcon>
  );
